<script lang="ts" setup>
import type { Review } from '~/types/api'

const { data: reviews } = await useReview()

useSchemaOrg([
  defineAggregateRating({
    itemReviewed: defineOrganization({
      name: 'Jagofon',
    }),
    ratingValue:
      reviews.value.reduce((acc, review) => acc + review.rating, 0) /
      reviews.value.length,
    reviewCount: reviews.value.length,
    bestRating: 5,
  }),

  ...reviews.value.map((review) =>
    defineReview({
      itemReviewed: defineOrganization({
        name: 'Jagofon',
      }),
      author: definePerson({
        name: review.name,
      }),
      reviewRating: {
        ratingValue: review.rating,
        bestRating: 5,
        worstRating: 1,
      },
      reviewBody: review.review,
    }),
  ),
])
</script>

<template>
  <UCarousel
    v-slot="{ item: review }: { item: Review }"
    arrows
    :items="reviews"
    :ui="{
      container: 'gap-6',
      item: 'basis-full md:basis-[calc(50%-0.75rem)] justify-center snap-start',
    }"
    :next-button="{ class: '-right-3' }"
    :prev-button="{ class: '-left-3' }"
  >
    <UCard
      class="w-full text-center"
      :ui="{ body: { padding: 'sm:py-12 mx-auto w-full' } }"
    >
      <h3 class="font-serif text-2xl">{{ review.name }}</h3>
      <UIRating :rating="review.rating" />
      <p
        class="line-clamp-3 text-sm leading-loose text-gray-600 md:text-base md:leading-loose"
      >
        {{ review.review }}
      </p>
    </UCard>
  </UCarousel>
</template>
